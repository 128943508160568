/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
		  
		  
		  
		  
		       function pressContent() {
            $('.ajax-container').css({
              display: 'inline-block'
            }).animate({
              "opacity": 1,
              'height': "100vh"
            }, 'fast');
            //$('.home-gallery').append('<div class="gallery-loader"><div class="loader__figure"></div></div>');
            //start ajax request
            //var arr0 = "https://api.thatslosangeles.net/wp-json/wp/v2/media?filter[orderby]=rand&filter[posts_per_page]=150";
            //var arr1 = "https://api.thatslosangeles.net/wp-json/wp/v2/media?filter[orderby]=rand&filter[posts_per_page]=250";
            //var arr2 = "https://api.thatslosangeles.net/wp-json/wp/v2/media?filter[orderby]=rand&filter[posts_per_page]=250";
            //var concatArray = arr0.concat(arr1, arr2);
            var json = null;
            $.ajax({
              // url: "https://api.thatslosangeles.net/wp-json/wp/v2/gallery-api?per_page=100",
              url: "https://api.thatslosangeles.net/wp-json/wp/v2/media?filter[orderby]=rand&filter[posts_per_page]=250",
              //url:"https://api.thatslosangeles.net/wp-json/wp/v2/media?filter[orderby]=rand&filter[posts_per_page]=150&filter[paged]=" + start,
              //timeout: 3000,
              cache: true,
              type: "GET",
              async: true,
              dataType: "json",
              beforeSend: function() {
                // setting a timeout
                //console.log("before send");
                $('.home-gallery').append('<div class="gallery-loader"><div class="loading_scene">' + '<svg version="1.1" id="dc-spinner" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width:"38"="" height:"38"="" viewBox="0 0 38 38" preserveAspectRatio="xMinYMin meet"><text x="12" y="21" font-family="Eurostile Next LT W04 SmBd Ex" font-size="0" style="letter-spacing:0.6;font-size: 16px;line-height: .8;height: inherit;font-family: &quot;Eurostile Next LT W04 Bold Ext&quot;;text-transform: uppercase;letter-spacing: .1px;" fill="black">LOADING<animate attributeName="opacity" values="0;1;0" dur="1.8s" repeatCount="indefinite"></animate></text></svg>' + '</div></div>');
                //$('.brand-holder').append('<div class="spinner-holder"><div class="spinner"><div class="spinner__item1"></div><div class="spinner__item2"></div><div class="spinner__item3"></div></div></div>');
              },
              success: function(data) {
                json = data;
                //console.log(json);
                //var obj = json[id].meta_box._gallery_images;
                var sliderContainer = document.getElementById("press_results"),
                  outerWrapper = '<div class="row">';
                for (var i = 0; i < json.length; i++) {
                  var item = json[i];
                  //var lrg_img = item.media_details.sizes.full.source_url;
                  var title = item.title.rendered;
				  var img_url = item.media_details.sizes.full.source_url;
                  
                  outerWrapper += '<div class="col-xs-12 col-sm-12 col-md-3">' + '<img data-lazy="' + img_url + '" src="/dist/img/white.gif" alt="' + title + '" />' + '</div>';
                }
                outerWrapper += '</div>';
             
              },
              complete: function() {
                //remove loading gif
                $('.gallery-loader').remove();
                //$('.spinner-holder').remove();
              }
            });
            //end ajax 
          }
          //start slider on init
        pressContent();
		  
		  
		  
		  
		  
		  
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
